interface Book {
  id: string
  slug: string
  title: string
  authors?: { name: string }[]
  primaryImageId?: string
}

const RECENT_SEARCHES_KEY = "recentSearches"
const MAX_RECENT_SEARCHES = 10

export function getRecentSearches(): Book[] {
  if (typeof window === "undefined") return []
  const data = localStorage.getItem(RECENT_SEARCHES_KEY)
  if (!data) return []
  try {
    return JSON.parse(data) as Book[]
  } catch (error) {
    console.error("Failed to parse recent searches from recent", error)
    return []
  }
}

export function addRecentSearch(book: Book): void {
  if (typeof window === "undefined") return
  const recentSearches = getRecentSearches()

  const existingIndex = recentSearches.findIndex((b) => b.id === book.id)
  if (existingIndex !== -1) {
    recentSearches.splice(existingIndex, 1)
  }
  recentSearches.unshift(book)
  const recentSearchesToStore = recentSearches.slice(0, MAX_RECENT_SEARCHES)
  localStorage.setItem(
    RECENT_SEARCHES_KEY,
    JSON.stringify(recentSearchesToStore)
  )
}

export function removeRecentSearch(bookId: string): void {
  if (typeof window === "undefined") return
  const recentSearches = getRecentSearches()
  const updatedSearches = recentSearches.filter((book) => book.id !== bookId)
  try {
    localStorage.setItem(RECENT_SEARCHES_KEY, JSON.stringify(updatedSearches))
  } catch (error) {
    console.error("Failed to remove recent search from recent", error)
  }
}

export function clearRecentSearches(): void {
  if (typeof window === "undefined") return
  localStorage.removeItem(RECENT_SEARCHES_KEY)
}
