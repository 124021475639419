"use client";

import React, { useState, useEffect, useRef, Suspense, useCallback } from "react";
import { Command, CommandEmpty, CommandGroup, CommandItem, CommandList } from "@/components/ui/command";
import { Input } from "@/components/ui/input";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useVirtualizer } from "@tanstack/react-virtual";
import { cn } from "@/lib/utils";
import { inter } from "@/app/fonts";
import { gql, useSuspenseQuery } from "@apollo/client";
import { useDebounce } from "@/hooks/useDebounce";
import Link from "next/link";
import { usePathname } from "next/navigation";
import BookCover from "./BookCover";
import PopoverContainer from "./PopoverContainer";
import { usePopover } from "@/hooks/usePopover";
import ButtonLoader from "./ButtonLoader";
import { addRecentSearch, getRecentSearches, removeRecentSearch } from "@/utils/recentSearches";
import { useResponsive } from "@/hooks/useResponsive";
interface Book {
  id: string;
  slug: string;
  title: string;
  authors?: {
    name: string;
  }[];
  primaryImageId?: string;
}
interface SearchBooksInputProps {
  placeholder?: string;
  addBook?: (book: Book) => void;
  className?: string;
  containerClassName?: string;
  parentClassName?: string;
  itemClassName?: string;
  commandListClassName?: string;
  iconSize?: number;
}
interface SearchResultsProps {
  debouncedQuery: string;
  addBook?: (book: Book) => void;
  parentVirtualRef: React.RefObject<HTMLDivElement>;
  itemClassName?: string;
  onSelect?: () => void;
}
const SEARCH_BOOKS_BY_TITLE = gql`
  query SearchBooksByTitle($title: String!) {
    searchBooksByTitle(title: $title) {
      id
      slug
      title
      authors {
        name
      }
      primaryImageId
    }
  }
`;
const SearchResults: React.FC<SearchResultsProps> = ({
  debouncedQuery,
  addBook,
  parentVirtualRef,
  itemClassName,
  onSelect
}) => {
  const shouldSkip = debouncedQuery.trim() === "";
  const {
    data
  } = useSuspenseQuery<{
    searchBooksByTitle: Book[];
  }, {
    title: string;
  }>(SEARCH_BOOKS_BY_TITLE, {
    variables: {
      title: debouncedQuery
    },
    skip: shouldSkip,
    errorPolicy: "all"
  });
  const matchedBooks = data?.searchBooksByTitle || [];
  const handleSelect = useCallback((book: Book) => {
    addRecentSearch(book);
    if (addBook) {
      addBook(book);
    }
    if (onSelect) {
      onSelect();
    }
  }, [addBook, onSelect]);
  return <BookList books={matchedBooks} addBook={addBook} parentVirtualRef={parentVirtualRef} itemClassName={itemClassName} onSelect={handleSelect} emptyMessage="No results found" data-sentry-element="BookList" data-sentry-component="SearchResults" data-sentry-source-file="SearchBooksInput.tsx" />;
};
const SearchBooksInput: React.FC<SearchBooksInputProps> = ({
  placeholder,
  addBook,
  className,
  containerClassName,
  parentClassName,
  itemClassName,
  commandListClassName
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const parentVirtualRef = useRef<HTMLDivElement>(null);
  const debouncedQuery = useDebounce(searchQuery, 350);
  const pathname = usePathname();
  const [hasInteracted, setHasInteracted] = useState(false);
  const {
    isMobile
  } = useResponsive();
  const {
    isOpen,
    setIsOpen,
    ref
  } = usePopover(isMobile);
  const [recentSearches, setRecentSearches] = useState<Book[]>([]);
  const refreshRecentSearches = useCallback(() => {
    const recent = getRecentSearches();
    setRecentSearches(recent);
  }, []);
  useEffect(() => {
    refreshRecentSearches();
  }, [refreshRecentSearches]);
  useEffect(() => {
    setSearchQuery("");
    setHasInteracted(false);
    setIsOpen(false);
  }, [pathname, setIsOpen, setHasInteracted]);
  useEffect(() => {
    if (hasInteracted && (debouncedQuery.trim() !== "" || recentSearches.length > 0)) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [debouncedQuery, setIsOpen, recentSearches, hasInteracted]);
  const handleInputFocus = useCallback(() => {
    setHasInteracted(true);
    if (debouncedQuery.trim() !== "" || recentSearches.length > 0) {
      setIsOpen(true);
    }
  }, [debouncedQuery, recentSearches, setIsOpen]);
  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setHasInteracted(true);
    setSearchQuery(e.target.value);
  }, []);
  const handleSelect = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);
  return <div className={cn("relative w-full", inter.className, parentClassName)} ref={ref} data-sentry-component="SearchBooksInput" data-sentry-source-file="SearchBooksInput.tsx">
      <Input icon={<SearchIcon />} type="text" onFocus={handleInputFocus} value={searchQuery} onChange={handleInputChange} onKeyDown={e => {
      if (e.key === "Enter") {
        e.preventDefault();
      }
    }} placeholder={placeholder || "Search by Title"} className={cn(className)} data-sentry-element="Input" data-sentry-source-file="SearchBooksInput.tsx" />

      <PopoverContainer isOpen={isOpen} style={{
      filter: "drop-shadow(0 5px 15px rgba(0, 5, 15, 0.35))"
    }} popoverClassName={cn("rounded-md absolute top-[60px] z-20 w-full min-w-[236px] sm:min-h-[384px] max-w-[513px] border border-[#bdbdbd]/10 max-sm:bg-transparent sm:bg-[#1d1e2f] p-0 text-sm text-white", inter.className, containerClassName)} arrowClassName="hidden" data-sentry-element="PopoverContainer" data-sentry-source-file="SearchBooksInput.tsx">
        <Command shouldFilter={false} className={cn("font-inter bg-[#10111e] sm:bg-[#1d1e2f] h-full max-sm:rounded-none")} data-sentry-element="Command" data-sentry-source-file="SearchBooksInput.tsx">
          <CommandList ref={parentVirtualRef} className={cn("custom-dropdown-scrollbar overflow-y-auto max-sm:h-[90vh] sm:max-h-[384px] ", commandListClassName)} data-sentry-element="CommandList" data-sentry-source-file="SearchBooksInput.tsx">
            <Suspense fallback={<div className="px-6 py-3 w-full flex items-center justify-center text-center text-sm text-white">
                  <ButtonLoader />
                </div>} data-sentry-element="Suspense" data-sentry-source-file="SearchBooksInput.tsx">
              {debouncedQuery.trim() === "" ? <RecentSearches parentVirtualRef={parentVirtualRef} itemClassName={itemClassName} onSelect={handleSelect} /> : <SearchResults debouncedQuery={debouncedQuery} addBook={addBook} parentVirtualRef={parentVirtualRef} itemClassName={itemClassName} onSelect={handleSelect} />}
            </Suspense>
          </CommandList>
        </Command>
      </PopoverContainer>
    </div>;
};
export default React.memo(SearchBooksInput);
const SearchIcon = () => {
  return <>
      {" "}
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" className="max-sm:hidden" data-sentry-element="svg" data-sentry-source-file="SearchBooksInput.tsx">
        <path d="M7.33333 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33333C12.6667 4.38781 10.2789 2 7.33333 2C4.38781 2 2 4.38781 2 7.33333C2 10.2789 4.38781 12.6667 7.33333 12.6667Z" stroke="white" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="SearchBooksInput.tsx" />
        <path d="M14.0016 14.0016L11.1016 11.1016" stroke="white" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="SearchBooksInput.tsx" />
      </svg>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="sm:hidden" data-sentry-element="svg" data-sentry-source-file="SearchBooksInput.tsx">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4 10C4 6.68629 6.68629 4 10 4C13.3137 4 16 6.68629 16 10C16 13.3137 13.3137 16 10 16C6.68629 16 4 13.3137 4 10ZM10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C11.8487 18 13.551 17.3729 14.9056 16.3199L20.2929 21.7071C20.6834 22.0976 21.3166 22.0976 21.7071 21.7071C22.0976 21.3166 22.0976 20.6834 21.7071 20.2929L16.3199 14.9056C17.3729 13.551 18 11.8487 18 10C18 5.58172 14.4183 2 10 2Z" fill="white" data-sentry-element="path" data-sentry-source-file="SearchBooksInput.tsx" />
      </svg>
    </>;
};
interface BookListProps {
  books: Book[];
  addBook?: (book: Book) => void;
  parentVirtualRef: React.RefObject<HTMLDivElement>;
  itemClassName?: string;
  onSelect?: (book: Book) => void;
  onDelete?: (book: Book) => void;
  emptyMessage?: string;
}
const BookList: React.FC<BookListProps> = ({
  books,
  addBook,
  parentVirtualRef,
  itemClassName,
  onSelect,
  onDelete,
  emptyMessage = "No results found"
}) => {
  const virtualizer = useVirtualizer({
    count: books.length,
    getScrollElement: () => parentVirtualRef.current,
    estimateSize: () => 96,
    overscan: 5
  });
  const handleSelect = React.useCallback((book: Book) => {
    if (addBook) {
      addBook(book);
    }
    if (onSelect) {
      onSelect(book);
    }
  }, [addBook, onSelect]);
  if (books.length === 0) {
    return <CommandEmpty className="px-6 py-3 text-center text-sm text-white">
        {emptyMessage}
      </CommandEmpty>;
  }
  return <ScrollArea data-sentry-element="ScrollArea" data-sentry-component="BookList" data-sentry-source-file="SearchBooksInput.tsx">
      <CommandGroup style={{
      height: `${virtualizer.getTotalSize()}px`,
      width: "100%",
      position: "relative"
    }} className="p-0" data-sentry-element="CommandGroup" data-sentry-source-file="SearchBooksInput.tsx">
        {virtualizer.getVirtualItems().map(vRow => {
        const book = books[vRow.index];
        return <div key={book.id} className="flex w-full flex-col" style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: `${vRow.size}px`,
          transform: `translateY(${vRow.start}px)`
        }}>
              <CommandItem className={cn("h-[96px] items-start w-full flex-1 border-b border-b-[#bdbdbd]/10 border-r-4 border-r-transparent bg-[#1d1e2f] px-4 py-3 leading-5 text-white aria-selected:bg-[#26273b] aria-selected:border-r-[#5069ce] aria-selected:text-white", itemClassName)} onSelect={() => handleSelect(book)}>
                <Link href={`/book/${book.slug}`} className="flex w-full items-center justify-between gap-5">
                  <div className="flex flex-grow items-center gap-3 font-medium">
                    <div className="h-[72px] w-[48px] overflow-hidden rounded-[4px]">
                      <BookCover book={book} width={48} height={72} className="h-full rounded-[4px] object-cover object-center" style={{
                    filter: "drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.35))"
                  }} />
                    </div>
                    <div className="flex flex-col gap-2">
                      <span className="text-sm font-bold text-white -tracking-[1%]">
                        {book.title.length > 27 ? `${book.title.slice(0, 27)}...` : book.title}
                      </span>
                      {book.authors && book.authors.length > 0 && <span className="text-xs font-medium text-[#e6e6fa]/70">
                          {book.authors[0].name}
                        </span>}
                    </div>
                  </div>
                </Link>
                {onDelete && <button onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              onDelete(book);
            }} className="ml-2 text-[#e6e6fa] hover:text-white focus:outline-none" aria-label={`Remove ${book.title} from recent searches`}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_3574_49412)">
                        <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="white" />
                      </g>
                      <defs>
                        <clipPath id="clip0_3574_49412">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>}
              </CommandItem>
            </div>;
      })}
      </CommandGroup>
    </ScrollArea>;
};
interface RecentSearchesProps {
  parentVirtualRef: React.RefObject<HTMLDivElement>;
  itemClassName?: string;
  onSelect?: () => void;
}
const RecentSearches: React.FC<RecentSearchesProps> = ({
  parentVirtualRef,
  itemClassName,
  onSelect
}) => {
  const [recentSearches, setRecentSearches] = useState<Book[]>([]);
  const refreshRecentSearches = useCallback(() => {
    const recent = getRecentSearches();
    setRecentSearches(recent);
  }, []);
  useEffect(() => {
    refreshRecentSearches();
  }, [refreshRecentSearches]);
  const handleSelect = useCallback((book: Book) => {
    addRecentSearch(book);
    if (onSelect) {
      onSelect();
    }
    refreshRecentSearches();
  }, [onSelect, refreshRecentSearches]);
  const handleDelete = useCallback((book: Book) => {
    removeRecentSearch(book.id);
    refreshRecentSearches();
  }, [refreshRecentSearches]);
  return <BookList books={recentSearches} parentVirtualRef={parentVirtualRef} itemClassName={itemClassName} onDelete={handleDelete} onSelect={handleSelect} emptyMessage="No recent searches" data-sentry-element="BookList" data-sentry-component="RecentSearches" data-sentry-source-file="SearchBooksInput.tsx" />;
};