import * as React from "react";
import { cn } from "@/lib/utils";
export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  icon?: React.JSX.Element;
}
const Input = React.forwardRef<HTMLInputElement, InputProps>(({
  className,
  icon,
  type,
  ...props
}, ref) => {
  // const [showPassword, setShowPassword] = React.useState(false)
  // const inputType = type === "password" && showPassword ? "text" : type

  // const togglePasswordVisibility = () => {
  //   setShowPassword((prevShowPassword) => !prevShowPassword)
  // }

  return <>
        {icon ? <div className={"relative flex items-center gap-2 rounded-md shadow-sm"}>
            <div className="pointer-events-none absolute left-3 max-sm:left-4 h-4 w-4 max-sm:h-6 max-sm:w-6">
              {icon}
            </div>
            <input type={type} className={cn("flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50", className)} ref={ref} {...props} />
          </div> :
    // <div className="relative">
    <input type={type} className={cn("flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50", className)} ref={ref} {...props} />
    // <div className="absolute right-3 top-1/2 flex -translate-y-1/2 items-center gap-2">
    //   {type === "password" && props?.value ? (
    //     <Button
    //       type="button"
    //       variant="link"
    //       onClick={togglePasswordVisibility}
    //       className="p-0"
    //     >
    //       <Image
    //         src={`${
    //           showPassword
    //             ? "/icons/sign-up/hide-password.svg"
    //             : "/icons/sign-up/show-password.svg"
    //         }`}
    //         width={24}
    //         height={24}
    //         alt="toggle password"
    //       />
    //     </Button>
    //   ) : null}
    // </div>
    // </div>
    }
      </>;
});
Input.displayName = "Input";
export { Input };