import Image from "next/image";
import { cn } from "@/lib/utils";
import React from "react";
import { IoBook } from "react-icons/io5";
type BookCoverProps = {
  book: {
    primaryImageId?: string;
    cover?: string;
    title?: string;
  };
  fallBackSize?: number;
} & Omit<React.ComponentProps<typeof Image>, "src" | "alt">;
const BookCover: React.FC<BookCoverProps> = props => {
  const {
    book,
    fallBackSize,
    width,
    height,
    className,
    ...rest
  } = props;
  if (book?.cover) return <Image className={cn(" object-cover object-center", className)} src={book.cover} alt={"Book cover"} width={width} height={height} {...rest} />;
  const {
    primaryImageId,
    title
  } = book;
  const hasImage = !!primaryImageId;
  const imageUrl = hasImage && `https://images.kaguya.io/books/${primaryImageId}-250w.webp`;
  return <>
      {imageUrl ? <Image className={cn(" object-cover object-center", className)} src={imageUrl} alt={title || "Book cover"} width={width} height={height} {...rest} /> : <BookCoverFallback width={width} height={height} fallBackSize={fallBackSize} />}
    </>;
};
export default BookCover;
type BookCoverFallbackProps = {
  width?: number | `${number}`;
  height?: number | `${number}`;
  fallBackSize?: number;
};
const BookCoverFallback = ({
  width,
  height,
  fallBackSize
}: BookCoverFallbackProps) => {
  return <div className={cn("aspect-[2/3] h-full w-full", height && `h-${height}px`, width && `w-${width}px`)} data-sentry-component="BookCoverFallback" data-sentry-source-file="BookCover.tsx">
      <div className={cn("flex h-full px-2.5 w-full items-center justify-center bg-white/[6%] rounded-[6px]")}>
        <IoBook size={fallBackSize || 44} className=" fill-[#e6e6fa]/50" data-sentry-element="IoBook" data-sentry-source-file="BookCover.tsx" />
      </div>
    </div>;
};