import { useState, useEffect, useCallback, useRef } from "react"
import useConditionalScrollLock from "./useConditionalScrollLock"

export function usePopover(shouldLockOnOpen: boolean = false) {
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  useConditionalScrollLock(isOpen && shouldLockOnOpen)

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsOpen(false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [handleClickOutside])

  return { isOpen, setIsOpen, ref }
}
